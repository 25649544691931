/*eslint-disable */
<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{ cvCardTitle }}</h4>
          </template>
          <template v-slot:body>
            <form action="#">
              <div class="form-row">
                <div class="col-md-12 mb-3">
                  <label for="validationblog_id">{{cvBlogIdLabel}}</label>
                    <input
                      v-model="vmblogFormData.blog_id"
                      type="text"
                      class="form-control"
                      required
                    />
                    <span class="primary-color"><small>E.g. BLOGa3b91a1e1a5247cf92c676f9b6ecb085, BLOGAPPTESTPREPSAT0000001, BLOGGPATHPROD01</small></span>
                </div>

                <div class="col-md-12 mb-3">
                    <label for="validationblog_title">{{cvBlogTitleLabel}} <span class="primary-color font-size-16">*</span></label>
                    <input
                      v-model="vmblogFormData.blog_title"
                      type="text"
                      class="form-control"
                      required
                    />
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationblog_video">{{cvBlogVideoLabel}}</label>
                  <input v-model="vmblogFormData.blog_video" type="text" class="form-control" required/>
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationblog_desc">{{cvBlogDescLabel}} <span class="primary-color font-size-16">*</span></label>
                  <vue-editor v-model="vmblogFormData.blog_desc"></vue-editor>
                </div>

                <!-- Blog Category -->
                <div class="col-md-12 mb-3" :key="Update">
                  <label for="validationblog_category">{{ cvBlogCateLabel }} <span class="primary-color font-size-16">*</span></label>
                  <select v-model="vmblogFormData.blog_category" class="form-control">
                    <option :value="null" disabled>Choose Blog Category</option>
                    <option  v-for="(category, index) of blogFiltersList['blog_category']" :key="('blog_category'+index+1)" :value="category.blog_category">{{ category.blog_category }}</option>
                    <option value="AddNew">Add New</option>
                  </select>
                  <div v-if="vmblogFormData.blog_category ==='AddNew'" style="margin-top: 5px" >
                    <input v-model="vmblogFormData.blog_category_new" placeholder="Enter the Blog category" type="text" class="form-control" required />
                  </div>
                </div><!-- Blog Category -->

                <!-- Upload Image -->
                <div class="col-md-12">
                  <label>{{ cvBlogImageLabel }} : {{ imageSize }} <span class="primary-color font-size-16">*</span></label>
                  <div class="form-group row align-items-center ml-1">
                    <div class="profile-img-edit border" style="height: 200px; width: 300px;">
                      <img v-if="vmCoverPic" :src="vmCoverPic" alt="profile-pic " style="height: 100%; width: 100%; object-fit: cover; overflow: hidden;" />
                      <i v-else class="fas fa-image d-flex justify-content-center align-items-center" style="font-size: 5rem; height:100%; color: #c1c1c1;"></i>
                      <label class="p-image">
                        <i class="ri-pencil-line upload-button" style="cursor: pointer;"></i>
                        <input class="file-upload" type="file" required accept="image/png,image/jpeg,image/jpg" id="fileInput1" style="display: block; position: absolute; opacity: 0; left: 0; top: 0; pointer-events: none;" @change="openImageDialog($event, 'Cover Pic')" />
                      </label>
                    </div>
                    <span class="text-danger" ref="coverPicError"></span>
                  </div>
                </div><!-- Upload Image -->

                <div class="col-md-12 mb-3">
                  <label for="validationblog_keywords">{{cvBlogKeywordsLabel}} <span class="primary-color font-size-16">*</span><small> (keyword should be comma (,) separated)</small></label>
                  <input
                    v-model="vmblogFormData.blog_keywords"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>

                <div class="col-md-12 mb-3">
                  <label for="validationblog_short_desc">{{cvBlogShortDescLabel}} <span class="primary-color font-size-16">*</span></label>
                  <input
                    v-model="vmblogFormData.blog_short_desc"
                    type="text"
                    class="form-control"
                    required
                  />
                </div>

                 <!-- Upload Documents -->
                 <div class="row col-12 w-100 mt-2">
                  <div class="col-md-12">
                    <label for="validationblog_attach">{{cvBlogAttachmentLabel}}</label>&nbsp;&nbsp;
                    <label for="excAttachmentFileInput" class="pointer primary-color">(Click here to upload)&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-upload"></i></label>
                    <input type="file" :accept="allowedExtensions" class="dontShow" id="excAttachmentFileInput" @change="onExtraCurricularAttatachmentChange($event, vmblogFormData, 'excAttachmentFileInput')">
                  </div>

                  <div class="col-md-12">
                    <template v-if="vmblogFormData.blog_attach && vmblogFormData.blog_attach.length">
                      <div v-for="(attachment, index) of vmblogFormData.blog_attach" :key="index">
                        <div class="attachmentCol mr-4">
                          <span class="pointer" @click="openFile(attachment.url)">{{ index+1 }}.&nbsp;&nbsp;&nbsp;&nbsp;{{ attachment.fileName ? attachment.fileName.split("/").pop() : attachment.url.split("/").pop() }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                          <img class="pointer" v-if="attachment.content" :src="pdfLogo" @click="openFile(attachment.url)">
                          <img v-else-if="attachment.fileName" :src="pdfLogo">
                          <img v-else alt="not found">
                          <span class="addIcon extraCloseIcon" @click="vmblogFormData.blog_attach.splice(index, 1)"><i class="fa-solid fa-circle-xmark"></i></span>
                        </div>
                      </div>
                    </template>
                  </div>
                </div><!-- Upload Documents -->
              </div>

              <div class="form-group mt-2">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="blogAdd()">{{ cvSubmitBtn }}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <CropImage :prop-crop-image-dialog-open="propCropImageDialogOpen" :propCropModalSize="propCropDialogSize" :prop-stencil-size="propStencilSize" :propSrc="fileSrc" @emitCroppedImg="getCroppedImg" @emitCroppedImgFile="emitCroppedImgFile" />

    <b-toast v-model="showToast" name="Toast" :auto-hide-delay="toastVariant === 'danger' ? 10000 : 1000" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { blogs } from "../../../FackApi/api/blog"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission.js"
import { VueEditor } from "vue2-editor"
import CropImage from "../../../components/cropImage.vue"
import { Images } from "../../../FackApi/api/image"
import pdfLogo from "../../../assets_gide/img/logo/pdfLogo.png"

export default {
  name: "blogAdd",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueEditor,
    CropImage
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  data () {
    return {
      cvCardTitle: "Add Blog",
      cvSubmitBtn: "Add",
      cvBlogIdLabel: "Blog ID",
      cvBlogTitleLabel: "Blog Title",
      cvBlogVideoLabel: "Blog Video",
      cvBlogDescLabel: "Blog Description",
      cvBlogImageLabel: "Blog Image",
      cvBlogCateLabel: "Blog Category",
      cvBlogKeywordsLabel: "Blog Keywords",
      cvBlogShortDescLabel: "Blog Short Description",
      cvBlogAttachmentLabel: "Attach Documents to this blog",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      cvLoadingStatus: false,
      toastTitle: "Blog Added",
      vmblogFormData: Object.assign({}, this.initFormData()),
      vmblogDesc: null,
      vmblogType: null,
      imageSize: "Size  1080 * 566",
      vmCoverPic: "",
      propCropImageDialogOpen: false,
      propCropDialogSize: "md",
      propStencilSize: null,
      fileSrc: "",
      blogFiltersList: [],
      Update: 0,
      pdfLogo: pdfLogo,
      allowedExtensions: "application/pdf,image/png,image/jpg,image/jpeg,image/gif,image/webp,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
      cvImageSizeErrorMsg: " Image size must be less than 300 kb.",
      cvVideoUrlMsg: "Only youtube url is supported",
      cvVideoUrlValidMsg: "Please only valid youtue video",
      errorMsg: "",
      videoErrMsg: "",
      cvVideoError: false
    }
  },
  async beforeMount () {
    // Auth Access for Current User Role
    if (this.userData.user_role == "USERROLE11114") {
      // We will display only the sessions of the user, It is necessary when the user has multiple sessions and he/she wants to view the session
      // DONT CARE CASE
    }
    else if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.blogFilterList("blog_category")
  },
  methods: {
    /**
     * getVideoEmbedeUrl()
     */
    getVideoEmbedeUrl () {
      if (!this.vmblogFormData.blog_video) {
        return
      }
      try {
        let url = this.vmblogFormData.blog_video
        let youtubeUrlRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm
        let videoIdRegex = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/

        // check url is whether youtube url or not
        let validUrl = youtubeUrlRegex.test(url)
        if (!validUrl) {
          this.cvVideoError = true
          this.videoErrMsg = this.cvVideoUrlMsg
          return false
        }

        let videoIdFound = url.match(videoIdRegex)
        if (!videoIdFound) {
          this.cvVideoError = true
          this.videoErrMsg = this.cvVideoUrlValidMsg
          return false
        }
        else {
          this.cvVideoError = false
          this.videoErrMsg = ""
          let videoId = videoIdFound[1]
          this.vmblogFormData.blog_video = `https://www.youtube.com/embed/${videoId}`
        }
      }
      catch (err) {
        console.error("Exception occurred at getVideoEmbedeUrl() and Exception:", err.message)
      }
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        "blog_id": "",
        "blog_title": "",
        "blog_video": "",
        "blog_desc": "",
        "blog_category": "",
        "blog_keywords": "",
        "blog_short_desc": "",
        "blog_attach": []
      }
    },
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmblogFormData) {
          if (!this.vmblogFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * blogAdd
     */
    async blogAdd () {
      // if (!this.validate()) {
      //   return false
      // }
      try {
        this.cvLoadingStatus = true

        if (this.vmblogFormData.blog_category_new) {
          this.vmblogFormData.blog_category = this.vmblogFormData.blog_category_new
        }

        let blogAddResp = await blogs.blogAdd(this, this.vmblogFormData)
        await ApiResponse.responseMessageDisplay(this, blogAddResp)
        if (blogAddResp && !blogAddResp.resp_status) {
          return false
        }

        if (blogAddResp.resp_data && blogAddResp.resp_data.blog_id) {
          await this.imageAdd(blogAddResp.resp_data.blog_id)
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseblogAddModal", blogAddResp.resp_data)
        }

        this.vmblogFormData = Object.assign({}, this.initFormData())
      }
      catch (err) {
        console.error("Exception occurred at blogAdd() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /*
     * blogFilterList
     */
    async blogFilterList (filterName = null) {
      if (!filterName) {
        return
      }
      try {
        let blogFilterListResp = await blogs.blogFilterList(this, filterName)
        if (blogFilterListResp) {
          this.blogFiltersList[filterName] = blogFilterListResp.resp_data
          this.Update += 1
        }
      }
      catch (err) {
        console.error("Exception occurred at blog_filter_list_resp() and Exception:", err.message)
      }
    },
    /**
     * openImageDialog
     */
    openImageDialog (e, imageType) {
      this.updateImageType = imageType
      if (e.target.files.length > 0) {
        this.fileSrc = e.target.files[0]
      }
      let input = e.target
      if (input.files.length > 0 && input.files[0]) {
        let image = input.files[0]
        let allowedExtension = [ "image/png", "image/jpg", "image/jpeg", "image/webp" ]
        let maxSize = 300 * 1024 // 300 KB in bytes
        if (!allowedExtension.includes(image.type)) {
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageExtensionErrorMsg
            this.showToast = true
          }
          return false
        }
        else if (image.size > maxSize) {
          // validate image size
          if (imageType === "Profile Pic") {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.showToast = true
          }
          else {
            this.toastMsg = this.cvImageSizeErrorMsg
            this.toastVariant = "danger"
            this.showToast = true
          }
          return false
        }
        this.propStencilSize = {
          "width": 540,
          "height": 283
        }
        this.propCropDialogSize = "md"
        this.propCropImageDialogOpen = Math.random()
      }
    },
    /**
     * onChangeProfileImage
     */
    onChangeProfileImage (e, imageType) {
      try {
        const reader = new FileReader()
        reader.onload = async (e) => {
          if (imageType === "Profile Pic") {
            this.vmProfilePic = await reader.result
          }
          else {
            this.vmCoverPic = await reader.result
          }
        }
        // reader.readAsDataURL(image)
      }
      catch (err) {
        console.error("Exception occurred at onChangeProfilePic() and Exception:", err.message)
      }
    },
    /**
     * getCroppedImg
     */
    getCroppedImg (img) {
      if (this.updateImageType === "Profile Pic") {
        this.vmProfilePic = img
      }
      else {
        this.vmCoverPic = img
      }
    },
    /**
     * emitCroppedImgFile
     */
    emitCroppedImgFile (imgObj) {
      let img = imgObj.imgFile
      let blogImg = imgObj.orgImage
      let imageType = this.updateImageType
      this.onChangeProfileImage(img, imageType)
      this.vmPBACoverPicImage = Object.assign({}, this.initImageData())
      this.vmPBACoverPicImage.image = {
        "img": img,
        "name": blogImg.name
      }
      this.vmPBACoverPicImage.image_type = "cover_pic"
    },
    /**
     * initImageData
     */
    initImageData () {
      return {
        image: "",
        image_type: ""
      }
    },
    /**
     * imageAdd
     */
    async imageAdd (blogId) {
      if (this.cvImageError) {
        return
      }
      try {
        this.cvImageLoadingStatus = true
        let imageObj = {
          "module_name": "BLOG",
          "module_id": blogId,
          "image": []
        }

        if (this.vmPBACoverPicImage.image) {
          imageObj.image.push(this.vmPBACoverPicImage)
        }

        if (imageObj.image.length < 1) {
          return false
        }

        const imageUpdateResp = await Images.imageProfileAdd(this, imageObj)
        await ApiResponse.responseMessageDisplay(this, imageUpdateResp)
        if (imageUpdateResp && !imageUpdateResp.resp_status) {
          return false
        }

        this.vmPBACoverPicImage = Object.assign({}, this.initPBAData())
        imageObj = {}
        return imageUpdateResp
      }
      catch (err) {
        console.error("Exception occurred at imageAdd() and Exception:", err.message)
        return err
      }
      finally {
        this.cvImageLoadingStatus = false
      }
    },
    /**
     * onExtraCurricularAttatachmentChange
     */
    onExtraCurricularAttatachmentChange (event, extraCurricular, inputId) {
      const fileList = event.target.files

      if (fileList && fileList.length) {
        for (let file of fileList) {
          // Allowed file types
          let allowedExtensions = this.allowedExtensions.split(",")

          let maxSize = 5 * 1024 * 1024 // 5MB limit

          if (!allowedExtensions.includes(file.type)) {
            this.toastMsg = "Invalid file type. Please upload a supported file."
            this.toastVariant = "danger"
            this.showToast = true
            continue
          }

          if (file.size > maxSize) {
            this.toastMsg = "File size exceeds the 5MB limit."
            this.toastVariant = "danger"
            this.showToast = true
            continue
          }

          // Read file and store in extraCurricular
          let reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = async (e) => {
            file.url = e.currentTarget.result
            file.fileName = file.name

            if (!extraCurricular.blog_attach) {
              extraCurricular.blog_attach = []
            }

            if (typeof extraCurricular.blog_attach === "string") {
              extraCurricular.blog_attach = JSON.parse(extraCurricular.blog_attach)
            }

            extraCurricular.blog_attach.unshift(file)

            // Reset input field
            const ele = document.getElementById(inputId)
            if (ele) {
              ele.value = ""
            }
          }
        }
      }
    },
    /**
     * openFile
     */
    openFile (url) {
      if (url === "") {
        // empty url means that univ admin has not accepted the application
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "This document can only be viewed after accepting the application"
        return
      }
      window.open(url, "_blank")
    }
  },
  watch: {
    "vmblogFormData.blog_video" () {
      this.getVideoEmbedeUrl()
    }
  }
}
</script>
<style lang="scss">
  .attachmentCol {
    img {
      height: 32px;
      width: 32px;
      object-fit: contain;
      border: 2px solid aliceblue;
      margin-bottom: 6px;
    }
  }
  .extraCloseIcon {
    font-size: 18px;
    position: relative;
    top: -15px;
    cursor: pointer;
    z-index: 99;
    margin-left: -9px;
    color: #e5252c;
  }
</style>
